<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :extra-validation="extraValidation"
    :extra-actions="extraActions"
    :submit-callback="submitCallback"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="formFields"
            allow-autofocus
          />
          <b-form-checkbox v-model="grantAllRights">
            {{ $t('Grant All Rights') }}
          </b-form-checkbox>
          <hr />
          <b-tabs v-if="rightsLoaded" pills vertical>
            <b-tab
              v-for="(rightGroup, index) in entity.rights"
              :key="rightGroup.title + index"
              :title="$t(rightGroup.title)"
              :active="index == 0"
            >
              <user-permissions-card :rights-group="rightGroup" :view-mode="false" />
              <b-form-checkbox v-model="grantGroupRights" :value="rightGroup.title">
                {{ $t('Grant Group Rights') }}
              </b-form-checkbox>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard, BFormCheckbox } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import { formUtils } from '@core/mixins/ui/forms'
import {
  getUserRights,
  isAdmin,
  isSeller,
  isSupplier,
  canEditPrice,
  canPublishToShop,
  canUnPublishFromShop,
} from '@/auth/utils'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import UserPermissionsCard from '../../users/UserPermissionsCard.vue'

import storeModule from '../userGroupStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
    BFormCheckbox,
    UserPermissionsCard,
  },
  mixins: [translationUtils, formUtils],
  data() {
    return {
      entity: {
        rights: [],
      },
      entityLoaded: false,
      rightsLoaded: false,
      grantAllRights: false,
      grantGroupRights: [],
      roleOptions: [],
    }
  },
  computed: {
    canPublish() {
      const result = canPublishToShop();
      return result;
    },
    rights() {
      return getUserRights()
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    canEditPrice() {
      return canEditPrice()
    },
    config() {
      return {
        store: storeModule,
        module: 'user-groups',
        endpoint: 'user-groups',
        route: 'user-groups',
        title: {
          single: this.$t('User Group'),
          plural: this.$t('User Groups'),
        },
      }
    },

    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'subtitle',
          type: 'text',
          label: this.$t('subtitle'),
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 1,
        },
        {
          id: 'subscription',
          type: 'checkbox',
          label: this.$t('Subscription'),
          colSize: 1,
        },
        {
          id: 'monthlySubscription',
          type: 'text',
          label: this.$t('Monthly Subscription'),
          dependency: 'subscription',
          required: true,
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'yearlySubscription',
          type: 'text',
          label: this.$t('Yearly Subscription'),
          dependency: 'subscription',
          required: true,
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'role',
          object: true,
          type: 'select',
          label: this.$t('role'),
          options: this.roleOptions,
          required: true,
          dependency: 'subscription',
          colSize: 3,
        },
        {
          id: 'maxUsers',
          type: 'number',
          label: this.$t('Maximum Users'),
          dependency: 'subscription',
          colSize: 2,
        },
        {
          id: 'maxProducts',
          type: 'number',
          label: this.$t('Maximum Products'),
          dependency: 'subscription',
          colSize: 2,
        },
        {
          id: 'maxPartners',
          type: 'number',
          label: this.$t('Maximum Partners'),
          dependency: 'subscription',
          colSize: 2,
        },
        {
          id: 'maxLeads',
          type: 'number',
          label: this.$t('Maximum Leads'),
          dependency: 'subscription',
          colSize: 2,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('description'),
          required: true,
          colSize: 12,
        },
      ]
    },
    extraActions() {
      const canPublish = canPublishToShop()
      const canUnPublish = canUnPublishFromShop(this.entity.supplier)
      return [
        {
          endpoint: 'publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          route: 'user-groups',
          actionValue: 'true',
          renderCondition: {
            key: 'published',
            value: false,
          },
          authorityValidation: canPublish,
        },
        {
          // callback: 'updateEshop',
          endpoint: 'publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          route: 'user-groups',
          actionValue: 'true',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canPublish,
        },
        {
          endpoint: 'publish',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          route: 'user-groups',
          actionValue: 'false',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canUnPublish,
        },
      ]
    },
  },
  mounted() {
    this.$watch('entityLoaded', this.fetchUserGroupRights)
    this.$watch('grantAllRights', this.allRightsGranted)
    this.$watch('grantGroupRights', this.groupRightsGranted)
    this.$store.dispatch('app-common/fetchRoles').then(response => {
      this.roleOptions = response.data
    })
  },
  methods: {
    extraValidation() {
      this.entity.userGroupRights = []
      this.entity.rights.forEach(group => {
        group.subgroups.forEach(subgroup => {
          const rights = Object.keys(subgroup)
          rights.forEach(right => {
            if (right !== 'title' && right !== 'canAssign' && subgroup[right].value) {
              this.entity.userGroupRights.push({
                rightId: subgroup[right].id,
                rightRightsGroup: group.title,
                rightRightsSubgroup: subgroup.title,
                rightTitle: right,
                hasRight: true,
                canAssign: subgroup.canAssign.value,
              })
            }
          })
        })
      })
      return ''
    },
    submitCallback() {
      const currentURL = window.location.pathname;
      const pattern = /^\/user-groups\/edit\/\d+$/;

      // if (pattern.test(currentURL)) {
      //   setTimeout(() => {
      //     this.$router.go(); // Reload the current route after 30 seconds
      //   }, 30 * 1000); // 30 seconds in milliseconds
      // } else {
      //   this.$router.push({ name: 'user-groups-list' });
      // }
      this.$router.push({ name: 'user-groups-list' });

    },

    allRightsGranted() {
    
      if (this.grantAllRights) {
        this.bulkAssignRights(null)
      }
    },
    groupRightsGranted() {
      if (this.grantGroupRights.length > 0) {
        this.bulkAssignRights(this.grantGroupRights)
      }
    },
    bulkAssignRights(groups) {
      this.entity.rights.forEach((group, gindex) => {
        if (groups != null && !groups.includes(group.title)) {
          return
        }
        group.subgroups.forEach((subgroup, sindex) => {
          const rights = Object.keys(subgroup)
          rights.forEach(right => {
            if (right !== 'title') {
              this.entity.rights[gindex].subgroups[sindex][right].value = true
            }
          })
        })
      })
    },
    fetchUserGroupRights() {
      if (this.entityLoaded) {
        this.$store
          .dispatch('app-common/fetchUserGroupRights', {
            id: this.entity.id,
          })
          .then(response => {
            this.entity.rights = this.formatRightsForTable(response.data)
            this.rightsLoaded = true
          })
      }
    },
  },
}
</script>
